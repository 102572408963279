<template>
  <span>
    <v-card-actions :class="textSizeLarge + ' paper--text mb-2 pa-0'">
      {{ item.calories ? item.calories : item.total_calories }} kCal
      <v-spacer />
    </v-card-actions>
    <v-row dense class=" py-0  px-0 mr-1">
      <v-col cols="5">
        <span :class="textSizeSmall + ' hybrid--text text--lighten-3' + fontWeight">
          FATS
        </span>
      </v-col>
      <v-col cols="7">
        <v-progress-linear
          class="ml-2"
          height="22"
          rounded
          background-color="charcoal lighten-1"
          color="hybrid lighten-1"
          :value="Math.round(item.percentage_fats)"
        >
          <template v-slot:default="{ value }">
            <span class="paper--text py-2">
              {{ Math.round(item.fats) }} g, {{ value }}%
            </span>
          </template>
        </v-progress-linear>
      </v-col>
      <v-col cols="5">
        <span :class="textSizeSmall + ' gold--text' + fontWeight">
          CARBS
        </span>
      </v-col>
      <v-col cols="7">
        <v-progress-linear
          class="ml-2"
          height="22"
          rounded
          background-color="charcoal lighten-1"
          color="gold darken-1"
          :value="Math.round(item.percentage_carbs)"
        >
          <template v-slot:default="{ value }">
            <span class="paper--text py-2">
              {{ Math.round(item.carbs) }} g, {{ value }}%
            </span>
          </template>
        </v-progress-linear>
      </v-col>
      <v-col cols="5">
        <span :class="textSizeSmall + ' progressActive--text' + fontWeight">
          PROTEINS
        </span>
      </v-col>
      <v-col cols="7">
        <v-progress-linear
          class="ml-2"
          height="22"
          rounded
          background-color="charcoal lighten-1"
          color="progressActive darken-2"
          :value="Math.round(item.percentage_proteins)"
        >
          <template v-slot:default="{ value }">
            <span class="paper--text py-2">
              {{ Math.round(item.proteins) }} g, {{ value }}%
            </span>
          </template>
        </v-progress-linear>
      </v-col>
    </v-row>
  </span>
</template>
<script>
import util from '@/mixins/util.js'
export default {
  name: 'BasicNutrition',
  mixins: [util],
  data: () => ({}),
  props: {
    item: {
      type: Object
    },
    fontWeight: {
      type: String,
      default: ' font-fontWeight-bold'
    }
  }
}
</script>
